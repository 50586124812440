import * as React from 'react';
import type { SVGProps } from 'react';
import IconWrapper, { IIconWrapperProps } from '../../IconWrapper';
function CaretDownFilledIcon({
  className,
  role,
  size,
  ...props
}: IIconWrapperProps & Omit<SVGProps<SVGSVGElement>, 'height' | 'width' | 'strokeWidth'>) {
  return React.createElement(
    IconWrapper,
    {
      role,
      size,
      ...props,
    },
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, 'uiiIcon', 'uiIcon-CaretDownFilledIcon'].filter((c) => !!c).join(' ')}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26352 10.8075C6.12746 10.6487 6.0397 10.4543 6.01063 10.2472C5.98156 10.0401 6.0124 9.82907 6.09948 9.63896C6.18657 9.44885 6.32627 9.28765 6.50207 9.17442C6.67787 9.06119 6.88241 9.00067 7.09152 9L16.9045 9C17.1136 9.00066 17.3182 9.06119 17.494 9.17442C17.6698 9.28765 17.8095 9.44885 17.8966 9.63896C17.9836 9.82907 18.0145 10.0401 17.9854 10.2472C17.9563 10.4543 17.8686 10.6487 17.7325 10.8075L13.198 16.071C13.0573 16.2343 12.883 16.3652 12.687 16.4551C12.4911 16.5449 12.2781 16.5914 12.0625 16.5915H11.9365C11.721 16.5914 11.508 16.5449 11.312 16.4551C11.1161 16.3652 10.9418 16.2343 10.801 16.071L6.26352 10.8075Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default CaretDownFilledIcon;
