import { LegacyPalette } from '@rmvw/c-common';
import * as React from 'react';
import RBDropdownItem, { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import styled from 'styled-components';

import { CanonicalSizePx } from '../../providers/ThemeProvider/themes';

export interface IDropdownItemProps extends DropdownItemProps {
  icon?: JSX.Element;
  danger?: boolean;
}

const _Icon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  transition: color 0.1s;
`;

const _Item = styled(RBDropdownItem)<{ $danger?: boolean }>`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  color: ${({ theme }) => theme.color.tertiaryColor};
  display: flex;
  gap: 8px;
  margin: 2px 0;
  padding: 6px 8px;
  transition: background-color 0.1s, color 0.1s;

  ${_Icon} {
    color: ${({ theme, $danger }) =>
      $danger
        ? theme.spec.scheme === 'light'
          ? theme.color.variant.danger.text
          : LegacyPalette.red40
        : theme.color.secondaryColor};
  }

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.color.background400};
    color: ${({ theme }) => theme.color.color};

    ${_Icon} {
      color: ${({ theme }) => theme.color.color};
    }
  }

  &.active,
  &:active {
    background-color: ${({ theme }) => theme.color.variant.primary.color};
    color: ${LegacyPalette.white};

    ${_Icon} {
      color: ${LegacyPalette.white};
    }
  }

  ${({ $danger, theme }) => {
    const isLightTheme = theme.spec.scheme === 'light';

    if ($danger) {
      return `
        color: ${isLightTheme ? theme.color.variant.danger.text : LegacyPalette.red40};

        &:focus,
        &:hover {
          background-color: ${theme.color.variant.danger.background};
          color: ${theme.color.variant.danger.text};

          ${_Icon} {
            color: ${theme.color.variant.danger.text};
          }
        }

        &.active,
        &:active {
          color: ${LegacyPalette.white};
          background-color: ${theme.color.variant.danger.color};

          ${_Icon} {
            color: ${LegacyPalette.white};
          }
        }
      `;
    }
  }}

  &.disabled,
  &:disabled {
    background-color: initial;
    color: ${({ theme }) => theme.color.secondaryColor};
    pointer-events: none;

    ${_Icon} {
      color: ${({ theme }) => theme.color.secondaryColor};
    }
  }
`;

const DropdownItem = React.forwardRef<HTMLElement, IDropdownItemProps>(({ children, danger, icon, ...props }, ref) => (
  <_Item {...props} $danger={danger} ref={ref}>
    {icon && <_Icon>{React.cloneElement(icon, { size: CanonicalSizePx.xxSmall })}</_Icon>}
    {children}
  </_Item>
));

DropdownItem.displayName = 'DropdownItem';

export default DropdownItem;
