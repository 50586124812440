import * as React from 'react';
import { useTheme } from 'styled-components';

import { AudienceType } from '../../___generated___/globalTypes';
import { useMarkThreadRead } from '../../hooks/useMarkRead';
import {
  IShortcutTarget,
  useAddShortcutMutation,
  useDeleteShortcutMutation,
  useGetShortcutForTarget,
} from '../../hooks/useShortcuts';
import { copyPermalink } from '../../lib/copyText';
import { getAudienceOptions } from '../audienceControls/getAudienceOptions';
import Dropdown from '../Dropdown';
import DropdownButton, { IDropdownButtonProps } from '../DropdownButton';
import useFollowControl from '../FollowControl/useFollowControl';
import {
  BookmarkIcon,
  BookmarkRemoveIcon,
  CameraIcon,
  DotsHorizontalIcon,
  LinkIcon,
  MailOpenIcon,
  NotificationIcon,
  NotificationOffIcon,
} from '../Icon';
import { ILoadedShareModalProps } from '../thread/share/ShareModal/LoadedShareModal';

interface ICopyLinkItemProps {
  icon?: JSX.Element;
  path: string;
}

export function CopyLinkItem(props: React.PropsWithChildren<ICopyLinkItemProps>) {
  return (
    <Dropdown.Item icon={props.icon ?? <LinkIcon />} onClick={() => copyPermalink(props.path)}>
      {props.children ?? <>Copy link</>}
    </Dropdown.Item>
  );
}

interface IMarkThreadReadItemProps {
  isThreadUnread: boolean;
  threadId: string;
}

export function MarkThreadReadItem({ isThreadUnread, threadId }: IMarkThreadReadItemProps) {
  const { markThreadRead } = useMarkThreadRead();

  // If the thread has unread messages, show the "Mark read" option
  if (isThreadUnread) {
    return (
      <Dropdown.Item icon={<MailOpenIcon />} onClick={() => markThreadRead(threadId)}>
        Mark read
      </Dropdown.Item>
    );
  } else {
    return null;
  }
}

export function FollowThreadItem({ following, threadId }: { following: boolean; threadId: string }) {
  const { setFollowing } = useFollowControl();

  return (
    <Dropdown.Item
      icon={following ? <NotificationOffIcon /> : <NotificationIcon />}
      onClick={() => setFollowing(threadId, !following)}
    >
      {following ? 'Unsubscribe' : 'Subscribe'}
    </Dropdown.Item>
  );
}

interface IStartVideoChatItemProps {
  isVideoChatActive: boolean;
  onClick: () => void;
}

export function StartVideoChatItem({ isVideoChatActive, onClick }: IStartVideoChatItemProps) {
  return (
    <Dropdown.Item icon={<CameraIcon />} onClick={onClick}>
      {isVideoChatActive ? 'Join Video Chat' : 'Start Video Chat'}
    </Dropdown.Item>
  );
}

interface IBookmarkThreadItemProps {
  shortcutTarget: IShortcutTarget;
}

export function ShortcutThreadItem({ shortcutTarget }: IBookmarkThreadItemProps) {
  const theme = useTheme();
  const shortcut = useGetShortcutForTarget(shortcutTarget.id);
  const [addShortcut, _addShortcutResult] = useAddShortcutMutation();
  const [removeShortcut, _removeShortcutResult] = useDeleteShortcutMutation();

  return (
    <Dropdown.Item
      icon={shortcut ? <BookmarkRemoveIcon /> : <BookmarkIcon />}
      onClick={async (e) => {
        e.stopPropagation();
        if (shortcut) {
          await removeShortcut(shortcut.id);
        } else {
          await addShortcut(shortcutTarget);
        }
      }}
    >
      {shortcut ? 'Remove shortcut' : 'Add shortcut'}
    </Dropdown.Item>
  );
}

interface IShareItemProps extends Pick<ILoadedShareModalProps, 'threadId' | 'threadType'> {
  audience: AudienceType;
  onToggleShareModal?: () => void;
}

export function ShareItem({ audience, onToggleShareModal, ...props }: IShareItemProps) {
  const options = getAudienceOptions({});
  const { Icon: AudienceIcon } = options[audience];

  return (
    <Dropdown.Item icon={<AudienceIcon />} onClick={onToggleShareModal}>
      Manage access...
    </Dropdown.Item>
  );
}

export type IActionsDropdownProps = IDropdownButtonProps;

function ActionsDropdown({ color, children, icon, title, ...props }: IActionsDropdownProps) {
  const size = props.size ?? 'medium';
  return (
    <DropdownButton
      {...props}
      hideCaret
      icon={icon ?? <DotsHorizontalIcon color={color} />}
      shape="circle"
      size={size}
      tooltip={title}
    >
      {children}
    </DropdownButton>
  );
}

export default ActionsDropdown;
