import { gql, useMutation, useQuery } from '@apollo/client';
import * as React from 'react';

import Logger from '../lib/observability/Logger';

import {
  HM_MarkThreadEventRead,
  HM_MarkThreadEventReadVariables,
  HM_MarkThreadRead,
  HM_MarkThreadReadVariables,
  HQ_LastThreadEvent,
  HQ_LastThreadEventVariables,
} from './___generated___/useMarkRead.types';

const MarkThreadReadMutation = gql`
  mutation HM_MarkThreadRead($input: MarkThreadReadInput) {
    markThreadRead(input: $input) {
      id
      firstUnreadMessageEvent {
        id
      }
      hasUnread
      newRepliesCount
      lastEvent {
        id
      }
      unreadMessageCount
    }
  }
`;

const MarkThreadEventReadMutation = gql`
  mutation HM_MarkThreadEventRead($input: MarkThreadEventReadInput) {
    markThreadEventRead(input: $input) {
      id
      readAt
      thread {
        id
        firstUnreadMessageEvent {
          id
        }
      }
    }
  }
`;

const GetLastThreadEventId = gql`
  query HQ_LastThreadEvent($threadId: ID!) {
    thread(id: $threadId) {
      lastEvent {
        id
      }
    }
  }
`;

export function useMarkThreadRead() {
  const [markThreadReadMutation, _threadResult] = useMutation<HM_MarkThreadRead, HM_MarkThreadReadVariables>(
    MarkThreadReadMutation
  );

  const markThreadRead = React.useCallback(
    (threadId: string) =>
      markThreadReadMutation({
        variables: {
          input: {
            threadId: threadId,
            readAt: new Date(),
          },
        },
      }).catch((e) => Logger.error(e, '[useMarkRead] Failed to mark thread read')),
    [markThreadReadMutation]
  );

  return React.useMemo(() => ({ markThreadRead }), [markThreadRead]);
}

export function useMarkThreadUnread(threadId: string) {
  const [markThreadEventReadMutation, _threadEventResult] = useMutation<
    HM_MarkThreadEventRead,
    HM_MarkThreadEventReadVariables
  >(MarkThreadEventReadMutation);
  const lastEventResult = useQuery<HQ_LastThreadEvent, HQ_LastThreadEventVariables>(GetLastThreadEventId, {
    variables: { threadId },
  });

  /**
   * WARNING: this does not work in conjunction with useMarkThreadEventRead.
   * The last event marked unread will not be suppressed and will be immediately marked unread
   */
  const markThreadUnread = React.useCallback(() => {
    const threadEventId = lastEventResult.data?.thread?.lastEvent?.id;
    if (threadEventId) {
      markThreadEventReadMutation({
        variables: { input: { threadEventId, readAt: null } },
      }).catch((e) => Logger.error(e, '[useMarkRead] Failed to mark thread unread'));
    }
  }, [lastEventResult.data?.thread?.lastEvent?.id, markThreadEventReadMutation]);

  return React.useMemo(() => ({ markThreadUnread }), [markThreadUnread]);
}

export function useMarkThreadEventRead(threadEventId: string) {
  const [suppressRead, setSuppress] = React.useState(false);

  const [markThreadEventReadMutation, _threadEventResult] = useMutation<
    HM_MarkThreadEventRead,
    HM_MarkThreadEventReadVariables
  >(MarkThreadEventReadMutation);

  const markThreadEventRead = React.useCallback(() => {
    markThreadEventReadMutation({
      variables: { input: { threadEventId, readAt: new Date() } },
    }).catch((e) => Logger.error(e, '[useMarkRead] Failed to mark thread event read'));
  }, [markThreadEventReadMutation, threadEventId]);

  const passiveMarkThreadEventRead = React.useCallback(() => {
    markThreadEventReadMutation({
      variables: { input: { threadEventId, readAt: new Date(), passive: true } },
    }).catch((e) => Logger.error(e, '[useMarkRead] Failed to mark thread event read'));
  }, [markThreadEventReadMutation, threadEventId]);

  const markThreadEventUnread = React.useCallback(() => {
    markThreadEventReadMutation({
      variables: { input: { threadEventId, readAt: null } },
    }).catch((e) => Logger.error(e, '[useMarkRead] Failed to mark thread event unread'));
    setSuppress(true);
  }, [markThreadEventReadMutation, threadEventId]);

  return React.useMemo(
    () => ({ suppressRead, markThreadEventRead, markThreadEventUnread, passiveMarkThreadEventRead }),
    [markThreadEventRead, markThreadEventUnread, passiveMarkThreadEventRead, suppressRead]
  );
}
