import * as React from 'react';
import styled from 'styled-components';

import { truncateText } from '../../lib/css';
import { EditIcon } from '../Icon';

export interface IEditableTextProps {
  className?: string;
  focus?: boolean;
  maxLength?: number;
  onEdit?: (text: string) => Promise<void>;
  showEditIcon?: boolean;
  text: string;
}

const _Input = styled.input`
  border: 1px solid ${({ theme }) => theme.color.border};
  font-feature-settings: inherit;
  font-weight: inherit;
  height: 100%;
  max-width: 100%;
  min-width: 26px;
  padding: 1px 0;
  width: 100%;

  &:focus {
    border-color: ${({ theme }) => theme.color.secondaryColor};
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    outline: none !important;
  }
`;

const _TextContainer = styled.div`
  cursor: text;
  display: flex;
  gap: 0.4em; /** gap between text and edit icon, proportional to surrounding text */
  height: 100%;
  min-width: 26px;
  padding: 2px 1px;

  &:hover {
    border-color: ${({ theme }) => theme.color.secondaryColor};
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    padding: 1px 0;
  }
`;

const _Text = styled.div`
  ${truncateText()}
`;

const _EditIcon = styled(EditIcon)`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: 0.75em; /** make edit icon smaller than surrounding text */
`;

function EditableText(props: IEditableTextProps) {
  const [isEditing, setIsEditing] = React.useState<boolean>(!!props.focus);
  const [text, setText] = React.useState<string>(props.text);

  function reset() {
    setIsEditing(false);
    setText(props.text);
  }

  return isEditing ? (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        await props.onEdit?.(text.slice(0, props.maxLength));
        setIsEditing(false);
      }}
      style={{ display: 'inline' }}
    >
      <_Input
        autoFocus
        className={props.className}
        maxLength={props.maxLength}
        size={Math.max(1, text.length)}
        type="text"
        value={text}
        onChange={(event) => {
          setText(event.target.value);
        }}
        onBlur={async (event) => {
          await props.onEdit?.(text);
          setIsEditing(false);
        }}
        onKeyDown={(event) => {
          if (event.key == 'Escape') {
            reset();
          }
        }}
      />
    </form>
  ) : (
    <_TextContainer
      className={props.className}
      onClick={() => {
        setText(props.text);
        setIsEditing(true);
      }}
    >
      <_Text title={props.text}>{props.text}</_Text>
      {props.showEditIcon && <_EditIcon />}
    </_TextContainer>
  );
}

export default EditableText;
