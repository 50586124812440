import { CourbetPalette } from '@rmvw/c-common';
import * as React from 'react';
import styled from 'styled-components';

import { CameraIcon } from '../Icon';

const _LiveVideoBadge = styled.div<{ $iconOnly?: boolean; $invert?: boolean }>`
  align-items: center;
  background-color: ${({ $invert }) => ($invert ? CourbetPalette.red600 : undefined)};
  border: 1px solid ${({ $invert }) => ($invert ? 'transparent' : CourbetPalette.red600)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  color: ${({ $invert }) => ($invert ? 'white' : CourbetPalette.red600)};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  gap: 2px;
  height: ${({ $iconOnly }) => ($iconOnly ? '18px' : 'auto')};
  justify-content: center;
  padding: 2px 4px;
  width: ${({ $iconOnly }) => ($iconOnly ? '18px' : 'fit-content')};
`;

export interface ILiveVideoBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  invert?: boolean;
  iconOnly?: boolean;
}

function LiveVideoBadge({ invert, iconOnly, ...props }: ILiveVideoBadgeProps) {
  return (
    <_LiveVideoBadge $iconOnly={iconOnly} $invert={invert} {...props}>
      <CameraIcon /> {!iconOnly && 'LIVE'}
    </_LiveVideoBadge>
  );
}

export default LiveVideoBadge;
