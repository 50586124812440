import { gql, useFragment_experimental, useMutation, useSubscription } from '@apollo/client';
import * as React from 'react';

import { NotificationDeliveryState, NotificationType } from '../___generated___/globalTypes';
import Logger from '../lib/observability/Logger';
import { IAudioPlayer } from '../lib/utils/AudioUtils';
import { useAppMeetingState } from '../providers/AppMeetingStateProvider';

import {
  ListenNotifications,
  ListenReadUpdate,
  useNotification_UpdateDesktopNotificationDeliveryState,
  useNotification_UpdateDesktopNotificationDeliveryStateVariables,
} from './___generated___/useNotifications.types';
import { useLoggedInAccount } from './useLoggedInAccount';
import useSoundEffects from './useSoundEffects';

type ThreadEventGQLType = NonNullable<ListenNotifications['notifications']>['threadEvent'];

const UpdateDesktopNotificationDeliveryState = gql`
  mutation useNotification_UpdateDesktopNotificationDeliveryState($id: ID!, $state: NotificationDeliveryState!) {
    updateDesktopNotificationDeliveryState(input: { id: $id, state: $state }) {
      id
    }
  }
`;

export default function useNotifications() {
  const account = useLoggedInAccount();
  const soundEffects = useSoundEffects();
  const { meetingState, showMeeting } = useAppMeetingState();

  const activeNotifications = React.useRef(new Map<string, Notification>());

  const [enableNotifications] = useMutation(
    gql`
      mutation EnableDesktopNotifications {
        registerForDesktopNotifications {
          id
        }
      }
    `
  );

  React.useEffect(() => {
    const requestPermission = async () => {
      if (!account) {
        // Don't request permission if user is not logged in
        return;
      }

      if (!window.Notification) {
        // This browser does not support native notifications
        return;
      }

      let permission = Notification.permission;
      if (permission === 'default') {
        permission = await Notification.requestPermission();
      }

      if (permission !== 'denied') {
        await enableNotifications().catch((e) => Logger.warn('[useNotification] Failed to enable notifications'));
      }
    };
    requestPermission().catch((e) => Logger.error(e as Error, '[useNotification] Failed to request permission'));
  }, [account, enableNotifications]);

  const [updateDesktopNotificationDeliveryState] = useMutation<
    useNotification_UpdateDesktopNotificationDeliveryState,
    useNotification_UpdateDesktopNotificationDeliveryStateVariables
  >(UpdateDesktopNotificationDeliveryState);

  function _notifyHelper({
    body,
    icon,
    id,
    key,
    target,
    title,
    threadEvent,
    type,
  }: {
    body?: string;
    icon?: string | undefined;
    id: string;
    key: string;
    target?: string;
    title: string;
    threadEvent?: ThreadEventGQLType;
    type: NotificationType;
  }) {
    // Note that Notification class doesn't exist in the context of mobile browsers
    if (!window.Notification || Notification.permission !== 'granted') {
      Logger.debug(`[useNotification] Notification permission not granted. Skipping Notification ${id}`);
      return;
    }

    const notification = new Notification(title, {
      body,
      icon,
      silent: true, // Default system sounds are crap. We'll manage our own sounds...
      tag: key,
    });

    let _notificationAudio: IAudioPlayer | undefined;

    activeNotifications.current.set(key, notification); // keep track of active notifications to support auto-dismissal

    notification.onclose = () => {
      _notificationAudio?.stop();
      activeNotifications.current.delete(notification.tag);
    };

    notification.onerror = async (event) => {
      _notificationAudio?.stop();
      Logger.warn(event, `[useNotification] Failed to display notification ${id}`);
    };

    notification.onshow = async () => {
      // Mark the notification as delivered upon display. Note that in the native Desktop app
      // we do not mark notification as delivered if screen is locked. This is because we
      // want to waterfall the notification to mobile (APNS or Email) if we are confident the
      // user is AFK
      if (await window._desktopBridge?.isScreenLocked?.()) {
        return;
      }

      updateDesktopNotificationDeliveryState({
        variables: { id, state: NotificationDeliveryState.DELIVERED },
      }).catch((e) => Logger.warn(e as Error, '[useNotification] Failed to update notification delivery state'));

      // Play custom notification sound if applicable
      switch (type) {
        case NotificationType.NEW_THREAD_EVENT: {
          switch (threadEvent?.__typename) {
            case 'MessageCreatedThreadEvent': {
              _notificationAudio = soundEffects.newMessage();
              break;
            }

            case 'MeetingCreatedThreadEvent': {
              _notificationAudio = soundEffects.newMeeting();
              break;
            }

            case 'AccessRequestThreadEvent': {
              _notificationAudio = soundEffects.accessRequest();
              break;
            }

            case 'AudienceMemberUpdatedThreadEvent': {
              _notificationAudio = soundEffects.accessGranted();
              break;
            }

            default:
              // Silent by default
              break;
          }
          break;
        }

        case NotificationType.INVITED_TO_MEETING: {
          // Sound effects handled in MeetingInvitationToast.tsx
          break;
        }

        case NotificationType.MEMBER_ADDED: {
          _notificationAudio = soundEffects.newMessage();
          break;
        }

        case NotificationType.REACTION: {
          _notificationAudio = soundEffects.newReaction();
          break;
        }

        default:
          // Silent by default
          break;
      }
    };

    if (target) {
      notification.onclick = (e: Event) => {
        if (
          meetingState &&
          threadEvent?.thread.__typename === 'Meeting' &&
          meetingState.seriesCode === threadEvent.thread.sourceMeetingSeries?.code
        ) {
          // The notification corresponds to the current meeting, so we should navigate to the meeting instead of the target
          showMeeting({ seriesCode: threadEvent.thread.sourceMeetingSeries.code });
        } else if (window._desktopBridge) {
          // On desktop, push target route into main window
          window._desktopBridge
            .pushMainWindowRoute?.({ path: new URL(target).pathname })
            .catch((e) => Logger.error(e as Error, '[useNotification] Failed to push main window route'));
        } else {
          // On web, open target in new window
          window.open(target, '_blank');
        }
        notification.close();
      };
    }
  }

  useSubscription<ListenNotifications>(
    gql`
      subscription ListenNotifications {
        notifications {
          id
          body
          targetUrl
          title
          image {
            id
            url: url_1024
          }
          key
          threadEvent {
            id
            thread {
              id

              # For meetings, look up the meeting series code so we can deep link to the meeting
              ... on Meeting {
                sourceMeetingSeries {
                  id
                  code
                }
              }
            }
          }
          type
        }
      }
    `,
    {
      onData: async ({ data }) => {
        const notification = data.data?.notifications;
        if (!notification) {
          return;
        }
        _notifyHelper({
          id: notification.id,
          body: notification.body,
          icon: notification.image?.url || undefined,
          key: notification.key,
          target: notification.targetUrl || undefined,
          title: notification.title,
          threadEvent: notification.threadEvent,
          type: notification.type,
        });
      },
    }
  );

  /**
   * Listen for updates to the read state on messages to clear notifications.
   * Instead of establishing a separate, redundant subscription, we listen for
   * updates to the subscription object.  This is not super tight but its less
   * wasteful and avoids some of the cache merging warnings that may be behind
   * some pubsub issues we're seeing.
   */
  const { data } = useFragment_experimental<ListenReadUpdate, unknown>({
    from: { __ref: 'ROOT_SUBSCRIPTION' },
    fragment: gql`
      ${subscriptionFragment}
      fragment ListenReadUpdate on Subscription {
        objectUpdateEvent {
          ...HF_useNotifications
        }
      }
    `,
    fragmentName: 'ListenReadUpdate',
  });

  React.useEffect(() => {
    // Close message based notification when message is read
    const event = data?.objectUpdateEvent?.threadEventRead;
    if (event && event.readAt) {
      if (event.__typename == 'MessageCreatedThreadEvent') {
        const messageId = event.message?.id;
        messageId && activeNotifications.current.get(messageId)?.close();
      }
    }

    // Close meeting based notification when participant joins or meeting ends
    const meeting = data?.objectUpdateEvent?.meetingParticipantJoined?.meeting ?? data?.objectUpdateEvent?.meetingEnded;
    if (meeting && meeting.id) {
      activeNotifications.current.get(meeting.id)?.close();
    }
  }, [data]);
}

const subscriptionFragment = gql`
  fragment HF_useNotifications on ObjectUpdateEvent {
    meetingEnded {
      id
    }
    meetingParticipantJoined {
      id
      meeting {
        id
      }
    }
    threadEventRead {
      id
      readAt
      ... on MessageCreatedThreadEvent {
        message {
          id
        }
      }
    }
  }
`;

useNotifications.fragment = subscriptionFragment;
