import { gql } from '@apollo/client';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { truncateText } from '../../../lib/css';
import { CanonicalSizePx } from '../../../providers/ThemeProvider/themes';
import { ProfileAvatar, ProfileAvatarById } from '../../Avatar';
import { CF_ProfileAvatar } from '../../Avatar/___generated___/ProfileAvatar.types';
import Dropdown from '../../Dropdown';
import DropdownButton from '../../DropdownButton';
import EditParentModal from '../../EditParentModal';
import { IDiscussionParent, useEditParentMutation } from '../../EditParentModal/hooks';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  DotsHorizontalIcon,
  PlusIcon,
  SwitchArrowsIcon,
  TrashIcon,
} from '../../Icon';
import BasePill from '../../ui/Pills/BasePill';

import { CF_ThreadOriginBreadcrumbs } from './___generated___/ThreadOriginBreadcrumbs.types';

const VISIBLE_BREADCRUMB_COUNT = 3;
const MAX_BREADCRUMB_WIDTH = 250;

const BREADCRUMB_TRUNCATION_THRESHOLD = 175;

export const BreadcrumbList = styled.div<{ $hide: boolean }>`
  align-items: center;
  display: flex;
  gap: 1px;
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
  transition: opacity 0.2s;
`;

const _CrumbContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1px;
  max-width: 100%;
  min-width: 0;
`;

const _BreadcrumbPill = styled(BasePill)`
  background-color: inherit;
  color: ${({ theme }) => theme.color.secondaryColor};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  height: 20px;
  max-width: ${MAX_BREADCRUMB_WIDTH}px;

  &:hover {
    background-color: ${({ theme }) => theme.color.background400};
    color: ${({ theme }) => theme.color.tertiaryColor};
  }
`;

const _DropdownItem = styled(Dropdown.Item)`
  display: flex;
  max-width: 250px;
`;

const _DropdownItemText = styled.span`
  ${truncateText()}
`;

type IBreadcrumb = IDiscussionParent & CF_ProfileAvatar;

export interface IThreadOriginBreadcrumbsProps {
  interactive?: boolean;
  thread: CF_ThreadOriginBreadcrumbs;
}

function ThreadOriginBreadcrumbs({ interactive = true, thread }: IThreadOriginBreadcrumbsProps) {
  const theme = useTheme();
  const [editOrigin] = useEditParentMutation();
  const [showEditModal, setShowEditModal] = React.useState(false);

  // reorder parents from nth-grandparent to parent
  const parents = [...(thread.parents ?? [])].reverse() as IBreadcrumb[];

  // Determine how many parents to render
  const _maxParents = Math.min(VISIBLE_BREADCRUMB_COUNT, parents.length);
  const [renderCount, setRenderCount] = React.useState(_maxParents);
  React.useEffect(() => setRenderCount(_maxParents), [_maxParents]);

  const { ref: containerRef, width = 0 } = useResizeObserver();
  React.useEffect(() => {
    // Determine how many parents to render based on available space
    if (width < BREADCRUMB_TRUNCATION_THRESHOLD) {
      setRenderCount(Math.min(1, _maxParents));
    } else if (width < BREADCRUMB_TRUNCATION_THRESHOLD * 2) {
      setRenderCount(Math.min(2, _maxParents));
    } else {
      setRenderCount(_maxParents);
    }
  }, [_maxParents, width]);

  let visibleParents: IBreadcrumb[] = [];
  let hiddenParents: IBreadcrumb[] = [];
  if (renderCount === 1) {
    // Only render closest parent
    visibleParents = [parents[parents.length - 1]];
    hiddenParents = parents.slice(0, parents.length - 1);
  } else if (renderCount === 2) {
    // Only render closest and farthest parent
    visibleParents = [parents[0], parents[parents.length - 1]];
    hiddenParents = parents.slice(1, parents.length - 1);
  } else {
    // render farthest parent and n-1 closest parents
    visibleParents = [parents[0], ...parents.slice(parents.length - renderCount + 1)];
    hiddenParents = parents.slice(1, parents.length - renderCount + 1);
  }

  const addOriginButton = (
    <_BreadcrumbPill onClick={() => setShowEditModal(true)} icon={<PlusIcon />}>
      Add to Team or Discussion
    </_BreadcrumbPill>
  );

  const editOriginDropdown = (
    <DropdownButton
      hideCaret
      icon={<ChevronDownIcon color={theme.color.secondaryColor} />}
      iconWidth={12}
      shape="circle"
      size="xxSmall"
      type="ghost"
    >
      <_DropdownItem icon={<SwitchArrowsIcon />} onClick={() => setShowEditModal(true)}>
        Change parent
      </_DropdownItem>
      <_DropdownItem icon={<TrashIcon />} onClick={() => editOrigin(thread.id, null)}>
        Remove parent
      </_DropdownItem>
    </DropdownButton>
  );

  let breadcrumbs: JSX.Element[] = [];
  if (parents.length > 0) {
    const ellipsisIndex = renderCount === 1 ? 0 : 1;
    breadcrumbs = visibleParents.map((parent, index) => (
      <_Breadcrumb
        interactive={interactive}
        key={`${parent.id}-${index}`}
        parent={parent}
        editOriginDropdown={index === visibleParents.length - 1 ? editOriginDropdown : undefined}
      />
    ));
    if (breadcrumbs.length > ellipsisIndex) {
      const ellipsis = <_EllipsisDropdown hiddenParents={hiddenParents} key={'ellipsis'} />;
      if (ellipsis) {
        breadcrumbs.splice(ellipsisIndex, 0, ellipsis);
      }
    }
  }

  if (parents.length === 0 && !interactive) {
    return null;
  }

  return (
    <BreadcrumbList $hide={parents.length === 0} ref={containerRef}>
      {parents.length ? breadcrumbs : interactive ? addOriginButton : null}
      <EditParentModal
        onHide={() => setShowEditModal(false)}
        show={showEditModal}
        thread={{ id: thread.id, parent: parents[parents.length - 1] ?? undefined }}
      />
    </BreadcrumbList>
  );
}

ThreadOriginBreadcrumbs.fragment = gql`
  ${ProfileAvatar.fragment}
  fragment CF_ThreadOriginBreadcrumbs on IThread {
    id
    name
    parents {
      id
      name
      permalink
      ...CF_ProfileAvatar
    }

    ... on Discussion {
      canEditOrigin
    }
  }
`;

interface IBreadcrumbProps {
  interactive: boolean;
  parent: IBreadcrumb;
  editOriginDropdown?: JSX.Element;
}

const _Breadcrumb: React.FC<IBreadcrumbProps> = ({ interactive, editOriginDropdown, parent }: IBreadcrumbProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { name, permalink } = parent;

  return (
    <_CrumbContainer>
      <_BreadcrumbPill
        icon={<ProfileAvatar profile={parent} size={CanonicalSizePx.xxSmall} />}
        onClick={() => navigate(permalink)}
      >
        {name}
      </_BreadcrumbPill>
      {!editOriginDropdown && <ChevronRightIcon color={theme.color.secondaryColor} size={12} />}
      {!!editOriginDropdown && interactive ? editOriginDropdown : undefined}
    </_CrumbContainer>
  );
};

interface IEllipsisDropdownProps {
  hiddenParents: IDiscussionParent[];
}

const _EllipsisDropdown: React.FC<IEllipsisDropdownProps> = ({ hiddenParents }: IEllipsisDropdownProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  if (hiddenParents.length === 0) {
    return null;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DropdownButton
        hideCaret
        icon={<DotsHorizontalIcon color={theme.color.secondaryColor} />}
        shape="circle"
        size="xxSmall"
        type="ghost"
      >
        {hiddenParents.map((parent, index) => (
          <_DropdownItem key={`${parent.id}-${index}`} onClick={() => navigate(parent.permalink)}>
            <ProfileAvatarById id={parent.id} size={CanonicalSizePx.xxSmall} />
            <_DropdownItemText>{parent.name}</_DropdownItemText>
          </_DropdownItem>
        ))}
      </DropdownButton>
      <ChevronRightIcon color={theme.color.secondaryColor} size={12} />
    </div>
  );
};

export default ThreadOriginBreadcrumbs;
