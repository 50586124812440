import { gql } from '@apollo/client';

import { ProfileAvatar } from '../Avatar';
import { DiscussionPreviewFragment } from '../Discussion/DiscussionPreview/useDiscussionPreviewQuery';
import LivePreview from '../meeting/LivePreview';
import MeetingMediaPreview from '../meeting/MeetingMediaPreview';
import { CF_ThreadEventReplies_GQL } from '../ThreadEventReplies/CF_ThreadEventReplies.gql';
import VideoChatPreviewCard from '../VideoChatPreviewCard';

export const EventFragmentsGQL = gql`
  ${CF_ThreadEventReplies_GQL}
  ${DiscussionPreviewFragment}
  ${LivePreview.fragment}
  ${MeetingMediaPreview.fragment}
  ${ProfileAvatar.fragment}
  ${VideoChatPreviewCard.CF_VideoChatPreviewCard}

  fragment ThreadEventFragments on IThreadEvent {
    id
    ...CF_ThreadEventReplies
    canReply
    highlightedBy {
      id
      ...CF_ProfileAvatar
    }
    permalink
    permissionScope {
      id
      name
    }
    reactions {
      emojiId
      count
      accounts {
        ...CF_ProfileAvatar
      }
      accountsIncludeMe
    }
    readAt
    replyThread {
      id
      following
      isDisabled
      messageCount
    }
    starred
    thread {
      id
      isDisabled
      name
      permalink
      ...CF_ProfileAvatar
    }
    time
    ... on AccessRequestThreadEvent {
      requestThread: thread {
        id
        ... on IAccountGroup {
          requests {
            account {
              id
            }
            status
          }
        }
      }
      requestor {
        id
        ...CF_ProfileAvatar
      }
    }
    ... on AudienceMemberUpdatedThreadEvent {
      actor {
        id
        name
      }
      members {
        ... on IProfile {
          id
          name
          permalink
        }
      }
      status
    }
    ... on AudienceUpdatedThreadEvent {
      actor {
        id
        name
      }
      audience
    }
    ... on DiscussionCreatedThreadEvent {
      discussion {
        id
        permalink
        subject
        creator {
          id
          name
          permalink
        }
        archived
        length
        # temporary to test collapsing PRs
        isPR
        ...HF_DiscussionPreview
      }
      convertedFrom {
        id
      }
    }
    ... on ThreadParentUpdatedThreadEvent {
      actor {
        id
        name
      }
      previousParent {
        id
        name
        permalink
      }
      updatedParent {
        id
        name
        permalink
      }
      updatedThread: updatedThread {
        id
        name
        permalink
      }
    }
    ... on DiscussionRenamedThreadEvent {
      actor {
        id
        name
      }
      previousName
      updatedName
    }
    ... on DiscussionStartThreadEvent {
      discussionStart: discussion {
        id
        name
        creator {
          id
          isMe
          name
          permalink
        }
      }
      origin {
        id
        name
        permalink
      }
    }
    ... on MeetingCreatedThreadEvent {
      meeting {
        id
        meetingParticipants {
          id
          account {
            id
            name
            permalink
          }
          state
        }
        ...CF_VideoChatPreviewCard
      }
    }
    ... on MeetingEndedThreadEvent {
      id
      # TODO
    }
    ... on MeetingParticipantsJoinedThreadEvent {
      meetingParticipants {
        id
        account {
          id
          isMe
          name
          permalink
        }
      }
    }
    ... on MeetingParticipantsLeftThreadEvent {
      meetingParticipants {
        id
        account {
          id
          isMe
          name
          permalink
        }
      }
    }
    ... on MeetingStartedThreadEvent {
      actor {
        id
        isMe
        name
        permalink
      }
    }
    ... on MentionedThreadEvent {
      actor {
        id
        name
        permalink
      }
      mentioned {
        __typename
        id
        name
        permalink
      }
      where {
        __typename
        id
        name
        permalink
        ... on Discussion {
          archived
        }
      }
    }
    ... on MessageCreatedThreadEvent {
      permalink
      message {
        id
        body
        bodyType
        creator {
          id
          activityStatus
          isMe
          name
        }
        date
        editedAt
        source
        thread {
          id
          permalink
        }
      }
    }
    ... on MessageScheduledThreadEvent {
      message {
        id
        creator {
          id
          name
          permalink
        }
      }
      scheduledFor
    }
    ... on NoteCreatedThreadEvent {
      note {
        id
        name
        threads {
          id
          notes {
            id
            name
          }
        }
      }
      account {
        id
        name
      }
    }
    ... on TagAddedThreadEvent {
      tagged {
        id
        name
        permalink
      }
      tag {
        id
        name
        permalink
      }
      taggedBy {
        id
        name
        permalink
      }
    }
    ... on TagRemovedThreadEvent {
      tagged {
        id
        name
        permalink
      }
      tag {
        id
        name
        permalink
      }
      removedBy {
        id
        name
        permalink
      }
    }
    ... on TeamStartedThreadEvent {
      creator {
        id
        isMe
        name
        permalink
      }
    }
    ... on ThreadEventMovedThreadEvent {
      actor {
        id
        isMe
        name
        permalink
      }
      threadEvent {
        id
        permalink
        thread {
          id
          name
        }
      }
    }
  }
`;
