import * as React from 'react';
import type { SVGProps } from 'react';
import IconWrapper, { IIconWrapperProps } from '../../IconWrapper';
function CaretRightFilledIcon({
  className,
  role,
  size,
  ...props
}: IIconWrapperProps & Omit<SVGProps<SVGSVGElement>, 'height' | 'width' | 'strokeWidth'>) {
  return React.createElement(
    IconWrapper,
    {
      role,
      size,
      ...props,
    },
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, 'uiiIcon', 'uiIcon-CaretRightFilledIcon'].filter((c) => !!c).join(' ')}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8075 17.7325C10.6487 17.8686 10.4543 17.9563 10.2472 17.9854C10.0401 18.0145 9.82907 17.9836 9.63896 17.8966C9.44885 17.8095 9.28765 17.6698 9.17442 17.494C9.06119 17.3182 9.00067 17.1136 9 16.9045L9 7.09152C9.00066 6.88241 9.06119 6.67787 9.17442 6.50207C9.28765 6.32627 9.44885 6.18657 9.63896 6.09948C9.82907 6.0124 10.0401 5.98156 10.2472 6.01063C10.4543 6.0397 10.6487 6.12746 10.8075 6.26352L16.071 10.798C16.2343 10.9388 16.3652 11.1131 16.4551 11.309C16.5449 11.505 16.5914 11.718 16.5915 11.9335V12.0595C16.5914 12.2751 16.5449 12.4881 16.4551 12.684C16.3652 12.88 16.2343 13.0543 16.071 13.195L10.8075 17.7325Z"
        fill="currentColor"
      />
    </svg>
  );
}
export default CaretRightFilledIcon;
